// @/utils/supabase/client.js
import { createBrowserClient } from '@supabase/ssr'

// Create a singleton instance
const supabaseClient = createBrowserClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL!,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
)

// Export the singleton
export const supabase = supabaseClient

// If you still need the factory function for some reason
export function createClient() {
  return supabaseClient
}